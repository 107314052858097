export default {
    methods: {
      getAllNotifications(params) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_BASEURL}notifications`,
            params:{...params, dont_paginate:true},
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  
      getNotification(id) {
          return new Promise((resolve, reject) => {
            const options = {
              method: "GET",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_BASEURL}notification/${id}`,
            };
            this.$http(options)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
  
      markNotificationAsRead(id) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_BASEURL}notification/notification/${id}/mark-as-read/`,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  
      deleteNotification(id) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "DELETE",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_BASEURL}notification/notification/${id}/`,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      markAllNotificationsAsRead() {
          return new Promise((resolve, reject) => {
            const options = {
              method: "POST",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_BASEURL}notifications/mark-all-as-read`,
            };
            this.$http(options)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
  
        getRouteFromNotificationType(type, target_id) {
          switch (type) {
            case "Task":
              if(target_id && target_id!=null){
                return `/tasks?task_id=${target_id}`
              }
              return "/tasks";
            case "RiskRegister":
              if(target_id && target_id!=null){
                return `/risk-register?register_id=${target_id}`
              }
              return "/risk-register";
            default:
              return "/";
          }
        },
    },
  };
  